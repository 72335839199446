import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import ProductList from "./pages/Products.js";
import CustomerProductList from "./pages/CustomerProducts.js";


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/Products" element={<ProductList />} />
                    <Route path="/CustomerProducts" element={<CustomerProductList />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
