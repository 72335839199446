import React, { useState, useEffect , useCallback} from 'react';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import truck from '../assets/truck.jpg'
import Button from './button.js'

export default function ProductList() {
    const navigate = useNavigate();
    const [verificationMessage, setVerificationMessage] = useState('');

    async function logout() {
  try {
    const response = await axios.get('http://3.80.194.65/api/logout/', {
      withCredentials: true
    });
    console.log('Logout successful:', response.data);
    console.log(verificationMessage);
    navigate("/");
  } catch (error) {
    console.error('Error occurred during logout:', error);
    // Handle logout error if needed
  }
}
    const verifyToken = useCallback(async () => {
    try {
      const response = await axios.get('http://3.80.194.65/api/token-verify/', {
        withCredentials: true
      });
      setVerificationMessage(response.data.message);
      console.log(response.data.message);
    } catch (error) {
      console.error(error);
      setVerificationMessage('Error verifying token');
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        quantity: '',
        image: null,
    });

    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setFormData({ ...formData, image: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataWithImage = new FormData();
            formDataWithImage.append('name', formData.name);
            formDataWithImage.append('description', formData.description);
            formDataWithImage.append('price', formData.price);
            formDataWithImage.append('quantity', formData.quantity);
            formDataWithImage.append('image', formData.image);

            const response = await axios.post('http://3.80.194.65/api/products/', formDataWithImage, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            window.location.reload();
            console.log('Product created successfully:', response.data);
            // Optionally, you can redirect the user to another page after successful creation
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };
    const [iformData, setiFormData] = useState({
        product: '',
        name: '',
        email: '',
        mobile_number: '',
        required_quantity: ''
    });

    const ihandleChange = (e) => {
        const { name, value } = e.target;
        setiFormData({
            ...iformData,
            [name]: value
        });
    };
    const ihandleSubmit = async (e) => {
       e.preventDefault();
        axios.post('http://3.80.194.65/api/orders/', iformData)
            .then(response => {
                console.log('Order placed successfully!');
            })
            .catch(error => {
                console.error('Failed to place order:', error);
            });
        setsendModal(false);
    };
    const [modal, setModal] = useState(false);
    const [updatemodal, setupdateModal] = useState(false);
    const [sendmodal, setsendModal] = useState(false);
    const [upid, setupid] = useState(null);
    const [deletion, setdeletion] = useState(false);
    const [editing, setediting] = useState(false);
    // const navigate = useNavigate();
    const toggleModal = () => {
        setModal(!modal);
    };
    const updatetoggleModal = (id) => {
        setupid(id);
        setupdateModal(true);
    };
    const updatetoggleModaloff = () => {
        setupdateModal(false);
    };
    const sendtoggleModal = (id) => {
        setiFormData({ ...iformData, product: id });
        setsendModal(true);
    };

    const sendModaloff = () => {
        setsendModal(false);
    };
    const toggleDelete = () => {
        setdeletion(!deletion);
    };
    const toggleEditing = () => {
        setediting(!editing);
    };
    const [products, setProducts] = useState([]);
    const [productid, setProductid] = useState(null);

    useEffect(() => {
        async function fetchProducts() {
            try {
                const response = await axios.get('http://3.80.194.65/api/products/');
                setProducts(response.data);
                if (response.data.length > 0) {
                    setProductid(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }
        fetchProducts();
    }, []);

    const handleDelete = async (id) => {
        try {
             await axios.delete(`http://3.80.194.65/api/products/${id}/`);
            setProducts(products.filter(product => product.id !== id));
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };
const changeid = (id) => {
    setProductid(id);
};

const renderProducts = () => {
        const rows = [];
        let row = [];

        products.forEach(product => {
            row.push(
                <div key={product.id} className="w-72 h-64 grid grid-rows-3 bg-[#292b2e] p-1 rounded-3xl ml-2 mt-2" onClick={() => changeid(product.id)}>
                    <div className="w-full h-full relative row-span-2">
                        <div className="w-full h-full rounded-3xl bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${product.image})`}}></div>
                    </div>
                    <div className="w-full h-full grid grid-cols-4 text-2xl">
                        <div className="w-full h-full col-span-3 flex items-center pl-4">{product.name}</div>
                        <div className="w-full h-full flex items-end justify-end pr-2 pb-2">{deletion&&(
                            <svg onClick={() => handleDelete(product.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 animate-pulse hover:fill-red-700 hover:text-black">
  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                        )}
                            {editing&&(
                                <svg onClick={() => updatetoggleModal(product.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 animate-pulse hover:fill-green-700 hover:text-black">
  <path stroke-linecap="round" stroke-linejoin="round" d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


                        )}
                        </div>
                    </div>
                </div>
            );
            if (row.length === 3 || products.indexOf(product) === products.length - 1) {
                rows.push(
                    <div key={rows.length} className="flex flex-wrap">
                        {row}
                    </div>
                );
                row = [];
            }
        });

        return rows;
    };

    const [UpdateformData, setUpdateFormData] = useState({
        name: '',
        description: '',
        price: '',
        quantity: '',
        image: null,
    });


    useEffect(() => {
        async function fetchProduct() {
            try {
                const response = await axios.get(`http://3.80.194.65/api/products/${upid}/`);
                setUpdateFormData(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        }
        fetchProduct();
    }, [upid]);

    const handleUpdateChange = (e) => {
        // Update the form data based on the input field
        if (e.target.name === 'image') {
            setUpdateFormData({ ...UpdateformData, [e.target.name]: e.target.files[0] });
        } else {
            setUpdateFormData({ ...UpdateformData, [e.target.name]: e.target.value });
        }
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        try {
            // Create form data to include the image file
            const formDataWithImage = new FormData();
            formDataWithImage.append('name', UpdateformData.name);
            formDataWithImage.append('description', UpdateformData.description);
            formDataWithImage.append('price', UpdateformData.price);
            formDataWithImage.append('quantity', UpdateformData.quantity);
            formDataWithImage.append('image', UpdateformData.image);

            await axios.patch(`http://3.80.194.65/api/products/${upid}/`, formDataWithImage, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            window.location.reload();
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };


    return (
        <div className="overflow-hidden w-full h-screen bg-black whitespace-pre-line text-white text-base font-normal font-['PP', 'Helvetica', 'Arial', 'sans-serif']">
            {modal && (
                <div className="w-full h-full top-0 bottom-0 right-0 left-0 fixed z-50">
                    <div onClick={toggleModal} className="bg-white bg-opacity-20 backdrop-blur-xl w-full h-full top-0 bottom-0 right-0 left-0 fixed"></div>
                    <div className="overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 line-height-[1.4] bg-[#292b2e] text-white p-[14px 28px] rounded-3xl max-w-[600px] min-w-[300px]">
                        <h2 className="text-3xl font-dm-sans font-thin break-words py-4 pl-4">Add Product</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-rows-8 h-[500px] w-[500px] gap-4 pb-4 px-4">
                                <div className="w-full h-full">
                                        <input placeholder="Product Name" type="text" name="name" value={formData.name} onChange={handleChange} className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full row-span-3">
                                    <textarea placeholder="Description" className="resize-none p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-3xl" name="description" value={formData.description} onChange={handleChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input placeholder="Price" className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" type="number" name="price" value={formData.price} onChange={handleChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input placeholder="Quantity" className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" type="number" name="quantity" value={formData.quantity} onChange={handleChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input type="file" name="image" accept="image/*" onChange={handleChange} required />
                                </div>
                                <div className="justify-center flex items-center w-full h-full">
                                    <input type="submit" className="bg-yellow-300 rounded-3xl text-center text-16 font-dm-sans font-medium break-words py-4 px-10 text-black" value="Create" />
                                </div>
                            </div>
                        </form>

                        <svg onClick={toggleModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 top-4 right-4 absolute z-40 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                    </div>
                </div>
            )}
            {updatemodal && (
                <div className="w-full h-full top-0 bottom-0 right-0 left-0 fixed z-50">
                    <div onClick={updatetoggleModaloff} className="bg-white bg-opacity-20 backdrop-blur-xl w-full h-full top-0 bottom-0 right-0 left-0 fixed"></div>
                    <div className="overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 line-height-[1.4] bg-[#292b2e] text-white p-[14px 28px] rounded-3xl max-w-[600px] min-w-[300px]">
                        <h2 className="text-3xl font-dm-sans font-thin break-words py-4 pl-4">Edit Product</h2>
                        <form onSubmit={handleUpdateSubmit}>
                            <div className="grid grid-rows-8 h-[500px] w-[500px] gap-4 pb-4 px-4">
                                <div className="w-full h-full">
                                        <input placeholder="Product Name" type="text" name="name" value={UpdateformData.name} onChange={handleUpdateChange} className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full row-span-3">
                                    <textarea placeholder="Description" className="resize-none p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-3xl" name="description" value={UpdateformData.description} onChange={handleUpdateChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input placeholder="Price" className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" type="number" name="price" value={UpdateformData.price} onChange={handleUpdateChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input placeholder="Quantity" className="p-2 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" type="number" name="quantity" value={UpdateformData.quantity} onChange={handleUpdateChange} required />
                                </div>
                                <div className="w-full h-full">
                                    <input type="file" name="image" accept="image/*" onChange={handleUpdateChange} required />
                                </div>
                                <div className="justify-center flex items-center w-full h-full">
                                    <input type="submit" className="bg-yellow-300 rounded-3xl text-center text-16 font-dm-sans font-medium break-words py-4 px-10 text-black" value="Update" />
                                </div>
                            </div>
                        </form>

                        <svg onClick={updatetoggleModaloff} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 top-4 right-4 absolute z-40 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                    </div>
                </div>
            )}
            <div className="w-full h-full grid grid-cols-10">
                <div className="w-full h-full bg-[#292b2e] grid grid-rows-6">
                    <div className="w-full h-full flex items-center justify-center">
                        <Link to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
</svg>

                        </Link>
                    </div>
                    <div className="w-full h-full row-span-4"></div>
                    <div className="w-full h-full flex items-center justify-center">
                        <Button onClick={logout} text="Logout" className="text-black bg-white right-0" type=""/>
                    </div>
                </div>
            <div className="w-full h-full col-span-6 grid grid-rows-7 overflow-hidden">
                <div className="w-full h-full grid grid-cols-5">
                    <div className="w-full h-full"></div>
                    <div className="w-full h-full flex items-center justify-center">
                        <Button onClick={toggleModal} text="Create" className="text-black bg-white right-0" type=""/>
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                        <Button onClick={toggleEditing} text="Update" className="text-black bg-white right-0" type=""/>
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                        <Button onClick={toggleDelete} text="Remove" className="text-black bg-white right-0" type=""/>
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="bg-white rounded-full p-4 ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-black">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
</svg>

                        </div>
                    </div>
                </div>
                    <div  className="w-full h-full pl-4 row-span-6 overflow-hidden">
                        <div className="w-full h-svh overflow-y-auto">
                        <div className="w-full h-72 px-4 py-2">
<div className="w-full h-full rounded-3xl bg-right-bottom bg-no-repeat bg-cover" style={{backgroundImage: `url(${truck})`}}>
    <div className="w-full h-full rounded-3xl bg-gradient-to-bl backdrop-blur-sm bg-white/30 from-white via-transparent to-transparent flex items-center pl-8 text-2xl font-thin">
        <div>
    <h1 className="text-3xl font-bold">Product Showcase</h1><br/>
    Send inquiry<br/>to proceed with<br/>further requirements...</div></div>
</div>
                </div>
                        <div className="w-full h-full">
                        {renderProducts()}
                            </div>
                        </div>
                    </div>
            </div>
                <div className="w-full h-full col-span-3 py-8 pr-6">
            {products
                .filter(product => product.id === productid)
                .map(product => (
        <div key={product.id} className="w-full h-full rounded-3xl grid grid-rows-9 bg-[#292b2e] gap-4">
                <div className="w-full h-full row-span-4 relative p-1">
                    <div className="w-full h-full rounded-3xl bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${product.image})`}}></div>
                </div>
                <div className="w-full h-full flex items-center px-4 text-2xl">
                    {product.name}
                </div>
                <div className="w-full h-full row-span-3 px-4">
                    <div className="w-full h-44 overflow-y-auto">
                        {product.description}
                    </div>
                </div>
                <div className="w-full h-full rounded-b-3xl px-4 grid grid-cols-3 row-span-2">
                    <div className="w-full h-full">Price:<br/>{product.price}</div>
                    <div className="w-full h-full">Quantity:<br/>{product.quantity}</div>
                    <div className="w-full h-full flex justify-end">
                        <button onClick={() => sendtoggleModal(product.id)} className="text-black bg-yellow-300 w-32 rounded-full h-12">Send Inquiry</button>
                    </div>
                </div>
            </div>
                ))}
                    </div>
                {sendmodal && (
                <div className="w-full h-full top-0 bottom-0 right-0 left-0 fixed z-50">
                    <div onClick={sendModaloff} className="bg-white bg-opacity-20 backdrop-blur-xl w-full h-full top-0 bottom-0 right-0 left-0 fixed"></div>
                    <div className="overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 line-height-[1.4] bg-[#292b2e] text-white p-[14px 28px] rounded-3xl max-w-[600px] min-w-[300px]">
                        <h2 className="text-3xl font-dm-sans font-thin break-words py-4 pl-4">Send Inquiry</h2>
                        <form onSubmit={ihandleSubmit}>
                            <div className="grid grid-rows-5 h-[500px] w-[500px] gap-4 pb-4 px-4">
                                <div className="w-full h-full">
                                        <input placeholder="Full Name" type="text" name="name" value={iformData.name} onChange={ihandleChange} className="py-2 px-4 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        value={iformData.email}
                                        onChange={ihandleChange}
                                        className="py-2 px-4 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full">
                                    <input
                                        placeholder="Mobile Number"
                                        type="text"
                                        name="mobile_number"
                                        value={iformData.mobile_number}
                                        onChange={ihandleChange}
                                    className="py-2 px-4 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full">
                                    <input
                                        placeholder="Required Quantity"
                                        type="text"
                                        name="required_quantity"
                                        value={iformData.required_quantity}
                                        onChange={ihandleChange}
                                    className="py-2 px-4 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="w-full h-full">
                                    <input
                                        type="hidden"
                                        name="product"
                                        value={iformData.product}
                                        onChange={ihandleChange}
                                    className="py-2 px-4 border-2 border-solid border-white w-full h-full bg-transparent rounded-full" required />
                                </div>
                                <div className="justify-center flex items-center w-full h-full">
                                    <input type="submit" className="bg-yellow-300 rounded-3xl text-center text-16 font-dm-sans font-medium break-words py-4 px-10 text-black" value="Send" />
                                </div>
                            </div>
                        </form>

                        <svg onClick={sendModaloff} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 top-4 right-4 absolute z-40 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                    </div>
                </div>
            )}
                </div>
        </div>
    );
}