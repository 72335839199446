import React from 'react';

const Button = ({ text, className ,type,onClick}) => {
  return (
    <button onClick={onClick} type={type} className={"duration-1000 hover:text-black hover:bg-white hover:shadow-lg hover:shadow-white border-2 border-solid border-white rounded-full px-8 py-4 " + className}>
      {text}
    </button>
  );
};

export default Button;
