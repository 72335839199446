import React, { useEffect} from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { gsap } from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import truck from '../assets/truck.jpg';
import truck5 from '../assets/truck5.jpeg';
import truck6 from '../assets/truck6.jpg';
import truck7 from '../assets/truck7.jpg';
import truck3 from '../assets/truck3.png';
import truck4 from '../assets/truck4.png';
import director1 from '../assets/director1.jpg';
import director2 from '../assets/director2.jpg';
import icon from '../assets/icon.png';
import Button from './button.js'
import SmoothScrollWrapper from '../smoother';
gsap.registerPlugin(ScrollTrigger);
export default function LandingPage() {
    const navigate = useNavigate();
    const navigation = [
        { name: 'HOME', id: 'home'},
        { name: 'ABOUT US', id: 'aboutus' },
        { name: 'WHY US', id: 'whyus' }
    ];
    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const truckmove =() =>{
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.stuck',
                start: 'top top',
                end: '+='+document.querySelector(".stuck").offsetWidth,
                scrub: 1,
                pin: true,
                markers: false
            }
        });
            tl.to('.truck', { width: "700px",height: "700px"});
            return () =>{
         tl.revert();
        }
    }
    useEffect(() => {
        // window.scrollTo(0, 0);
        const navbar = document.getElementById('navbar');
        function updateNavbarPosition() {
            if (window.scrollY >= 10) {
                navbar.style.backdropFilter = 'blur(20px)';
            } else {
                navbar.style.backdropFilter = 'blur(0px)';
            }
        }
        window.addEventListener('scroll', updateNavbarPosition);
        updateNavbarPosition();
        truckmove();
    }, []);
    return (
        <SmoothScrollWrapper>
        <div id="home" className="max-lg:text-sm overflow-hidden relative whitespace-pre-line text-white bg-black text-base font-normal w-full h-full font-['PP', 'Helvetica', 'Arial', 'sans-serif']">
            <nav id="navbar" className="mynav w-full h-32 max-md:h-24 grid  grid-cols-9 max-lg:grid-cols-4 items-center z-[1000] top-0 left-0 fixed">
                <div className="w-full h-full text-container flex items-center justify-center">
                    <img className="w-24 h-24 max-lg:w-16 max-lg:h-16 cursor-pointer" src={icon} alt=""/>
                </div>
                <div className="max-md:hidden flex  gap-x-24 max-md:gap-x-4 justify-center col-span-7 items-center w-full h-full max-lg:col-span-2">
                        {navigation.map((item) => (
                            <div className="group">
                            <button
                                id="navlist"
                                key={item.name}
                                onClick={() => {
                                    if (item.href) {
                                        navigate(item.href);
                                    } else if (item.onClick) {
                                        item.onClick();
                                    }
                                    else if(item.id) {
                                        scrollToSection(item.id);
                                    }
                                }}
                                className="leading-6"
                            >
                                {item.name}
                            </button>
                            <div className="group-hover:transition-all group-hover:duration-1000 group-hover:ease-in-out group-hover:w-full w-0 h-1 rounded-full bg-white mt-2"></div>
                            </div>
                        ))}
                    </div>
                <Link to="/CustomerProducts" className="w-full h-full flex justify-center items-center max-md:hidden">
                    <Button text="PRODUCTS" />
                </Link>
            </nav>
            <div className="w-full max-md:h-screen max-md:relative">
                <div className="absolute z-0 w-full h-screen max-md:h-3/4 bg-right-bottom bg-no-repeat bg-cover blur-sm opacity-80" style={{backgroundImage: `url(${truck})`}}></div>

            <div className="w-full h-screen max-md:h-3/4 max-md:px-1 flex items-center relative z-10">
                <div className="w-full grid grid-rows-4 gap-y-4">
                    <div className="text-white font-bold  text-6xl max-lg:text-5xl max-md:text-4xl tracking-wider flex  items-center max-md:items-end justify-center">Spreading Your</div>
                <div className="text-white font-bold  text-6xl max-lg:text-4xl max-md:text-3xl tracking-wider flex items-center justify-center">Supply Chain</div>
                <div className="text-white font-normal text-base max-md:text-sm flex items-center justify-center tracking-widest text-center">The logistics industry expertise to support your business and add<br/>value across your supply chain.</div>
                <div className="w-full h-full flex items-center justify-center">
                    <Button onClick={() => scrollToSection("footer")} text="GET IN TOUCH" />
                </div>
                </div>
            </div>
            </div>
            <div className="w-full h-[500px] grid grid-rows-3">
                <div className="w-full h-full flex  items-end max-md:items-start justify-center">
                    <h1 className="font-normal text-5xl flex items-center justify-center tracking-widest text-center">Things About<br/>Selina Exporters</h1>
                </div>
                <div className="w-full h-full  items-center max-md:items-start justify-center flex">
                    <h1 className="font-normal text-base flex items-center justify-center tracking-widest text-center">We are your trusted partners, ready to navigate the<br/>complex world of logistics together.</h1>
                </div>
                <div className="w-full h-full grid  grid-cols-8 max-lg:grid-cols-4 max-md:gap-y-4 max-md:grid-rows-4  text-base">
                    <div className="w-full h-full col-span-2 max-lg:hidden"></div>
                    <div className="w-full h-full flex items-start justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                    <h1 className="font-normal tracking-widest text-center">Long Haul</h1>
                    </div>
                    <div className="w-full h-full flex items-start justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                        <h1 className="font-normal tracking-widest text-center">Storage</h1>
                    </div>
                    <div className="w-full h-full flex items-start justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                        <h1 className="font-normal tracking-widest text-center">Intermediate</h1>
                    </div>
                    <div className="w-full h-full flex items-start justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                        <h1 className="font-normal tracking-widest text-center">Cross Docking</h1>
                    </div>
                    <div className="w-full h-full col-span-2 max-lg:hidden"></div>
                </div>
            </div>
            <div className="w-full h-screen flex items-end relative">
                <div className="absolute z-0 w-full h-screen bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${truck3})`}}></div>
                {/*<img className="absolute z-0 w-full h-full max-lg:h-3/4" src={truck3}/>*/}
                <div id="aboutus" className="w-full h-56 bg-white rounded-t-3xl"></div>
            </div>
            <div className="w-full h-screen grid grid-cols-3 px-32 max-lg:px-10 gap-12">
                <div className="w-full h-full text-left grid grid-rows-5">
                    <div className="w-full h-full flex items-end justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-12 h-12">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
</svg>

                    </div>
                    <div className="w-full h-full flex items-center justify-start text-3xl max-lg:text-2xl">Our Origin Story</div>
                    <div className="w-full h-full flex items-start justify-start row-span-3 text-xl max-lg:text-sm text-gray-400">

Established amidst the challenges of the Covid-19 pandemic in September 2020, Selina Exporters Private Limited was born from a vision to contribute to India's export growth while navigating the complexities of global trade. Founded by Ilyas Ahmed and Selina Ahmed, our journey began with a mission to strengthen India's export potential in agricultural products and stone boulders.
                    </div>
                </div>
                <div className="w-full h-full text-center grid grid-rows-5">
                    <div className="w-full h-full flex items-end justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-12 h-12">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288" />
</svg>


                    </div>
                    <div className="w-full h-full flex items-center justify-center text-3xl max-lg:text-2xl">Our Journey</div>
                    <div className="w-full h-full flex items-start justify-center row-span-3 text-xl max-lg:text-sm text-gray-400">

From our humble beginnings in the village of Panishala, Chagrabandha, District Coochbehar, West Bengal, we embarked on a journey of entrepreneurship driven by a commitment to adhere to all regulations and uphold ethical business practices. Armed with essential licenses and tax registrations, we secured our first letter of credit from a Bangladesh importer, marking the commencement of our export operations.
                    </div>
                </div>
                <div className="w-full h-full text-right grid grid-rows-5">
                    <div className="w-full h-full flex items-end justify-end">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-12 h-12 fill-white">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
</svg>


                    </div>
                    <div className="w-full h-full flex items-center justify-end text-3xl max-lg:text-2xl">Facing Challenges</div>
                    <div className="w-full h-full flex items-start justify-end row-span-3 text-xl max-lg:text-sm text-gray-400">

The initial months presented numerous challenges, particularly navigating the closure of borders due to the pandemic. Despite these obstacles, we persevered, employing innovative solutions and adhering to government guidelines to ensure the continuity of our business operations. With determination and resilience, we overcame these hurdles, laying the foundation for our success.
                    </div>
                </div>

            </div>
            <div className="w-full h-full grid lg:grid-cols-2 max-lg:grid-rows-2 mb-10 px-12 stuck">
                <div className="w-full h-full flex items-center justify-center">
                    <img src={truck4} className="w-[200px] h-[200px] truck" alt=""/>
                </div>
                <div className="w-full h-full grid grid-rows-8">
                    <div className="w-full h-full flex justify-center items-center text-5xl tracking-widest font-thin">Authority</div>
                    <div className="w-full h-full text-xl text-gray-400 text-center grid grid-cols-2 row-span-5">
                        <div className="w-full h-full grid grid-rows-2">
                            <div className="w-full h-full flex items-end justify-center">
                                <div className="w-44 h-48 rounded-full bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${director1})`}}></div>
                            </div>
                            <div className="w-full h-full flex items-center justify-center">
                                (FOUNDER DIRECTOR AND AUTHORISED SIGNATORY)<br/>DIN 08887836<br/>(ILYAS AHMED)
                            </div>
                        </div>
                        <div className="w-full h-full grid grid-rows-2">
                            <div className="w-full h-full flex items-end justify-center">
                                <div className="w-44 h-48 rounded-full bg-top bg-no-repeat bg-cover" style={{backgroundImage: `url(${director2})`}}></div>
                            </div>
                            <div className="w-full h-full flex items-center justify-center">(FOUNDER DIRECTOR)<br/>DIN 08887837<br/>(SELINA AHMED)</div>
                        </div>
                    </div>
                    <div className="w-full h-full flex items-start justify-center row-span-2">
                         <Button onClick={() => scrollToSection("footer")} text="CONTACT US" type=""/>
                    </div>
                </div>
            </div>
            <div className="w-full h-screen max-lg:h-full grid lg:grid-cols-3 max-lg:grid-rows-3" id="whyus">
                <div className="w-full h-full grid lg:grid-rows-2 max-lg:grid-cols-2">
                    <div className="w-full h-full overflow-hidden relative">
                        <div className="w-full h-full absolute z-20 bg-gradient-to-tr from-gray-600 via-30% via-gray-600 to-transparent grid grid-rows-4 px-8">
                            <div className="w-full h-full flex items-end text-xl">Our<br/>Vision</div>
                            <div className="w-full h-full flex items-center justify-center row-span-3 max-lg:text-xs">At Selina Exporters Private Limited, we envision becoming a leading exporter, not only generating income but also fostering sustainable economic growth for India. We are committed to leveraging our expertise to enhance export opportunities, contributing to the prosperity of our nation and empowering local communities.</div>
                        </div>
                        <img className="w-full h-full" src={truck5} alt=""/>
                    </div>
                    <div className="w-full h-full grid grid-rows-3">
                        <div className="w-full h-full row-span-2 flex items-center justify-center text-8xl max-lg:text-4xl font-bold">500MT+</div>
                        <div className="w-full h-full flex items-center justify-center text-xl max-lg:text-base text-gray-400 text-center">Export operations commenced on 8th December 2020 onwards</div>
                    </div>
                </div>
                <div className="w-full h-full grid lg:grid-rows-2 max-lg:grid-cols-2">
                    <div className="w-full h-full overflow-hidden relative">
                        <div className="w-full h-full absolute z-20 bg-gradient-to-tr from-orange-600 via-30% via-orange-600 to-transparent grid grid-rows-4 px-8">
                            <div className="w-full h-full flex items-end text-xl">Our<br/>Legacy</div>
                            <div className="w-full h-full flex items-center justify-center max-lg:text-xs row-span-3">Our journey is rooted in a legacy of entrepreneurship and social responsibility. Prior to founding Selina Exporters, we supported woodcraft artisans in North Bengal, providing them with sustained employment and financial stability. Additionally, we established a tailoring unit in Nagpur, generating employment opportunities for rural families from Nandigram, West Bengal. Despite facing challenges such as the implementation of GST, our commitment to empowering communities remains unwavering.</div>
                        </div>
                        <img className="w-full h-full absolute" src={truck6} alt=""/>
                    </div>
                    <div className="w-full h-full grid grid-rows-3">
                        <div className="w-full h-full row-span-2 flex items-center justify-center text-8xl max-lg:text-4xl font-bold">$5000+</div>
                        <div className="w-full h-full flex items-center justify-center text-xl max-lg:text-base text-gray-400 text-center">Monthly with each ton earning approximately $10</div>
                    </div>
                </div>
                <div className="w-full h-full grid lg:grid-rows-2 max-lg:grid-cols-2">
                    <div className="w-full h-full relative">
                        <div className="w-full h-full absolute z-20 bg-gradient-to-tr from-violet-600 via-30% via-violet-600 to-transparent grid grid-rows-4 px-8">
                            <div className="w-full h-full flex items-end text-xl">Our<br/>Mission</div>
                            <div className="w-full h-full flex items-center justify-center max-lg:text-xs row-span-3">As we continue to expand our presence in the export market, we invite you to join us on our journey. Together, we can create meaningful impact, driving economic growth, and fostering sustainable livelihoods for generations to come.</div>
                        </div>
                        <img className="w-full h-full absolute" src={truck7} alt=""/>
                    </div>
                    <div className="w-full h-full grid grid-rows-3">
                        <div className="w-full h-full row-span-2 flex items-center justify-center text-8xl max-lg:text-4xl font-bold">100+</div>
                        <div className="w-full h-full flex items-center justify-center text-xl max-lg:text-base text-gray-400 text-center">Up to 100 satisfied client have work with us</div>
                    </div>
                </div>
            </div>
            <div id="footer" className="w-full h-96 bg-[#7B7B7B] bottom-0 mt-32 grid grid-rows-6">
                <div className="w-full h-full row-span-5 grid grid-cols-2">
                    <div className="w-full h-full grid grid-rows-6 px-8 py-8">
                        <div className="w-full h-full text-lg flex items-end">
                            OPENING HOURS
                        </div>
                        <div className="w-full h-full row-span-5 grid grid-rows-3">
                            <div className="w-full h-full grid grid-cols-2">
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    Mon-Thu:
                                </div>
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    8am-9pm
                                </div>
                            </div>
                            <div className="w-full h-full grid grid-cols-2">
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    Fri-Sat:
                                </div>
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    8am-1am
                                </div>
                            </div>
                            <div className="w-full h-full grid grid-cols-2">
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    Sun:
                                </div>
                                <div className="w-full h-full border-b-2 border-solid border-white flex items-center justify-center">
                                    9am-10pm
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full grid grid-rows-6 px-8 py-8">
                        <div className="w-full h-full text-lg flex items-end">
                            CONTACT US
                        </div>
                        <form className="w-full h-full row-span-5 grid grid-rows-5 pt-4">
                            <div className="w-3/5 h-14 relative row-span-2">
                                <input type="email" className="w-full h-full bg-transparent border-2 border-solid border-white rounded-full py-2 pl-4 absolute" placeholder="Enter Email"/>
                                <Button text="SEND" className="h-full text-black bg-white absolute z-30 right-0" type="submit"/>
                            </div>
                            <div className="w-full h-full row-span-4 mt-4 grid grid-rows-3">
                                <div className="w-full h-full grid grid-cols-6 gap-2">
                                    <div className="w-full h-full flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>

                                    </div>
                                    <div className="w-full h-full col-span-5 text-lg tracking-widest items-center flex">Changrabandha Koch Bihar, West Bengal</div>
                                </div>
                                <div className="w-full h-full grid grid-cols-6 gap-2">
                                    <div className="w-full h-full flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>


                                    </div>
                                    <div className="w-full h-full col-span-5 text-lg tracking-widest items-center flex">selinaexporters@gmail.com</div>
                                </div>
                                <div className="w-full h-full grid grid-cols-6 gap-2">
                                    <div className="w-full h-full flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>


                                    </div>
                                    <div className="w-full h-full col-span-5 text-lg tracking-widest items-center flex">+91 9422809286</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <a href="https://selinaexporters.com" className="w-full h-full bg-[#3E3E3E] flex items-center justify-center text-lg tracking-widest">selinaexporters.com</a>
            </div>
    </div>
            </SmoothScrollWrapper>
    );
}